import router, { useRouter } from "next/router";
import { useEffect } from "react";
import { ChevronRight } from "react-feather";
import { Button } from "ui";
import { Header } from "ui/profile/edit/header";
import css from "./mobile-edit-menu.module.scss";
import { useMobileMenuStore } from "../../../../store/mobile-menu";
import { useClientQuery, useGetFirstRegistrationQuery } from "generated/types";

export function MobileEditMenu({
  routes,
  onBack,
}: {
  routes: {
    title: string;
    path: string;
  }[];
  onBack: () => void;
}) {
  const { data: firstRegistration } = useGetFirstRegistrationQuery({
    fetchPolicy: "no-cache",
  });

  const mobileMenuStore = useMobileMenuStore();
  const router = useRouter();

  const isFirstRegistration = firstRegistration?.getFirstRegistration;

  useEffect(() => {
    // overflow hidden
    document.body.style.overflow = "hidden";

    return () => {
      // overflow visible
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    if (mobileMenuStore.open) {
      window.scrollTo(0, 0);
    }
  }, [mobileMenuStore.open]);

  return (
    <div className={css.Root}>
      <div className={css.Header}>
        <span className={css.Title}>
          {isFirstRegistration ? "Создание" : "Редактирование"} профиля
        </span>
      </div>
      <div className={css.Routes}>
        {routes.map((route, index) => (
          <>
            <div className={css.Divider} />
            <div
              className={css.Route}
              key={route.path}
              onClick={() => {
                router.push(route.path);
                // if router pathname === route.path - close menu
                if (router.pathname === route.path) {
                  mobileMenuStore.close();
                }
              }}
            >
              <span>{route.title}</span>
              <ChevronRight
                size={16}
                stroke="#B6BBBF"
                height={24}
                width={24}
                strokeWidth={1}
              />
            </div>
            {
              // last router - divider
              index === routes.length - 1 && <div className={css.Divider} />
            }
          </>
        ))}
      </div>
    </div>
  );
}
