import Head from "next/head";
import React, {useEffect} from "react";
import {useUserContext} from "application/context/user-context-provider";
import {Hidden, Profile} from "ui";
import HeaderMenu from "./header-menu";
import CabinetButtons from "./cabinet-buttons";
import css from "./cabinet-layout.module.scss";
import classNames from "classnames";
import {MobileEditMenu} from "features/profile/extra/mobile-edit-menu";
import {
  TariffRequestEnum,
  useGetApplicationCountQuery,
} from "generated/types";
import {useRouter} from "next/router";
import {useMobileMenuStore} from "../../../../store/mobile-menu";
import FooterMenu from "./footer-menu";
import clsx from "clsx";
import {RouteEnum} from "common/enum/route.enum";
import {useDaysTillLeft} from "application/hooks/useDaysTillLeft";
import {TariffsButton} from "features/tariffs/components/TariffsButton";
import {useModalStore} from "../../../../store/modal";
import {Notification} from "./components";
import Link from "next/link";
import {LockIcon} from "ui/button/LockIcon";
import {LinkEnum} from "common/enum/links.enum";
import {LKIcon} from "./cabinet-layout.svg";
import {useEventPageStore} from "../../../../store/event-page";

function RenderMobileMenu() {
  const mobileMenuStore = useMobileMenuStore();
  const router = useRouter();

  useEffect(() => {
    if (mobileMenuStore.open) mobileMenuStore.toggle();
  }, [router.pathname]);

  if (!mobileMenuStore.open) return null;

  return (
    <MobileEditMenu
      onBack={() => {
        mobileMenuStore.toggle();
      }}
      routes={[
        {
          title: "Профиль компании",
          path: "/profile/edit/profile",
        },
        {
          title: "Аудитория",
          path: "/profile/edit/audience",
        },
        {
          title: "Ресурсы",
          path: "/profile/edit/resources",
        },
        {
          title: "Партнерство",
          path: "/profile/edit/partners",
        },
        {
          title: "Оформление страницы",
          path: "/profile/edit/styling",
        },
      ]}
    />
  );
}

type Props = {
  title?: string;
  mainClassName?: string;
  disableFooter?: boolean;
};

const CabinetLayout: React.FC<Props> = ({
                                          title = "Личный кабинет",
                                          mainClassName,
                                          disableFooter = false,
                                          children,
                                        }) => {
  const router = useRouter();

  const modal = useModalStore();

  const {
    userContext: {user},
  } = useUserContext();

  const {data: applicationCountData} = useGetApplicationCountQuery({
    pollInterval: 3000,
  });

  const store = useEventPageStore(state => state)

  const daysTillLeft = useDaysTillLeft(user?.tariff?.expiredAt, [user?.tariff]);

  const shortName = user?.name.slice(0, 2).toUpperCase() || "ЛК";

  const aWeekLeft = daysTillLeft && daysTillLeft <= 7 && daysTillLeft >= 0;
  //const haveSubsc = user?.tariff?.status === "ACTIVE" && user?.tariff;

  const isCollaborationsPage = RouteEnum.COLLABORATIONS === router.pathname;

  const expiredAt =
    user?.tariff?.expiredAt &&
    new Date(user.tariff.expiredAt).toLocaleDateString();

  const isPro =
    user?.tariff && user?.tariff?.status === TariffRequestEnum.Active;

  return (
    <>
      <Head>
        <title>Eventica - {title}</title>
        <meta name="description" content=""/>
        <link rel="icon" href="/favicon.ico"/>
        <link rel="preload" href="/img/event-modal-bg.jpg" as="image"/>
        <link rel="preload" href="/img/event-modal-bg-mobile.jpg" as="image"/>
        <link rel="preload" href="/img/become-pro-modal-bg.jpg" as="image"/>
        <link
          rel="preload"
          href="/img/become-pro-modal-bg-mobile.jpg"
          as="image"
        />
      </Head>
      <div className={css.Root}>
        <div
          className={css.Wrapper}
          style={{background: isCollaborationsPage ? "#f7f7f7" : undefined}}
        >
          <header className={css.Header}>
            <Link href={"/partnership"}>
              <div className={css.Logo}/>
            </Link>
            <Hidden tablet>
              <HeaderMenu/>
            </Hidden>
            {user ? (
              <div className={css.UserDashboard}>
                {isPro && (
                  <>
                    <div
                      className={clsx({
                        [css.UserDashboard__subscription]: true,
                        [css.UserDashboard__subscription_show]:
                        aWeekLeft && isPro, // Показывает, если до окончания подписки осталось 7 и менее дней
                      })}
                    >
                      PRO истекает {expiredAt}
                    </div>

                    <TariffsButton
                      className={clsx({
                        [css.UserDashboard__button]: true,
                        [css.UserDashboard__button_hide]:
                        !aWeekLeft || (!isPro && aWeekLeft), // Скрываем, если по подписке осталось больше недели
                      })}
                      onClick={() => router.push(RouteEnum.TARIFFS)}
                      variant="orange"
                    >
                      Продлить
                    </TariffsButton>
                  </>
                )}

                {!isPro && (
                  <TariffsButton
                    className={clsx({
                      // [css.UserDashboard__button]: true,
                      [css.UserDashboard__button_hide]:
                      (aWeekLeft && isPro) || isPro, // Скрываем, если есть подписка или до окончания подписки осталось 7 и менее дней
                    })}
                    onClick={() => router.push(RouteEnum.TARIFFS)}
                    variant="orange"
                  >
                    Стать PRO
                  </TariffsButton>
                )}

                <TariffsButton
                  className={css.UserDashboard__button_ask_question}
                  variant="blue"
                >
                  <Link
                    href={LinkEnum.ASK_QUESTION}
                    target="_blank"
                    style={{color: "inherit"}}
                  >
                    Задать вопрос
                  </Link>
                </TariffsButton>

                {/* {isPro ? ( */}
                <Notification
                  count={applicationCountData?.getApplicationCount ?? 0}
                />
                {/* ) : (
                  <></>
                )} */}

                <CabinetButtons
                  notifications={applicationCountData?.getApplicationCount ?? 0}
                  name={shortName}
                  onNotifications={() => {
                  }}
                  onEmails={() => {
                  }}
                  onName={() => {
                  }}
                />
              </div>
            ) : (
              <div className={css.UserDashboard}>
                <TariffsButton
                  className={css.UserDashboard__button_ask_question}
                  variant="blue"
                  onClick={() => router.push("/signup")}
                >
                  Зарегистрироваться
                </TariffsButton>
                <TariffsButton
                  className={css.UserDashboard__button_signin}
                  variant="orange"
                  onClick={() => router.push("/signin")}
                >
                  Войти
                </TariffsButton>
                <div className={css.ClientButton}>
                  <LKIcon/>
                </div>
              </div>
            )}
          </header>
          <RenderMobileMenu/>
          <main className={classNames(css.Main, mainClassName)}>
            {children}
          </main>

          {/*{!disableFooter &&*/}
          {/*  !store.isLoading &&*/}
          {/*  <Profile.Footer background={isEventPage ? '#63228C' : 'inherit'}/>*/}
          {/*}*/}
          {!disableFooter && !store.isLoading &&
            <Profile.Footer background={setBackgroundColor(router.pathname)}/>
          }
          <FooterMenu/>
        </div>
      </div>
    </>
  );
};

export default CabinetLayout;


const setBackgroundColor = (pathname: string) => {
  switch (pathname) {
    case '/event/[id]' :
      return '#63228C'
    case '/partnership' :
      return '#F7F7F7'
    default:
      return 'inherit'
  }
}