import React, {useMemo} from "react";
import Link from "next/link";
import {useRouter} from "next/router";
import css from "./footer-menu.module.scss";
import {RouteEnum} from "common/enum/route.enum";
import {CollaborationIcon, EventsIcon, FreelanceIcon, ProfileIcon} from "./footer-menu.svg";
import {useUserContext} from "../../context/user-context-provider";
import {LockIcon} from "../../../features/events/pages/search-events-page.svg";

const MENU_ITEMS_COMMON = [
  {
    icon: <CollaborationIcon/>,
    label: "Партнёрства",
    url: RouteEnum.PARTNERSHIP,
    pattern: RouteEnum.PARTNERSHIP,
    enabled: true,
  },
  {
    icon: <EventsIcon/>,
    label: "Ивенты",
    url: RouteEnum.EVENTS,
    pattern: RouteEnum.EVENTS,
    enabled: true,
  },
  {
    icon: <FreelanceIcon />,
    label: "Услуги",
    url: RouteEnum.SERVICES,
    pattern: RouteEnum.SERVICES,
    enabled: true,
  },
];

const MENU_ITEMS_USER = [
  {
    icon: <ProfileIcon/>,
    label: "Профиль",
    url: "/profile",
    pattern: "/",
    enabled: true,
  },
  {
    icon: <CollaborationIcon/>,
    label: "Партнёрства",
    url: RouteEnum.PARTNERSHIP,
    pattern: RouteEnum.PARTNERSHIP,
    enabled: true,
  },
  {
    icon: <EventsIcon/>,
    label: "Ивенты",
    url: RouteEnum.EVENTS,
    pattern: RouteEnum.EVENTS,
    enabled: true,
  },
  {
    icon: <FreelanceIcon />,
    label: "Услуги",
    url: RouteEnum.SERVICES,
    pattern: RouteEnum.SERVICES,
    enabled: true,
  },
];

const FooterMenu: React.FC = () => {
  const router = useRouter();

  const {
    userContext: {user},
  } = useUserContext();

  const MENU_ITEMS = useMemo(() => {
    if (typeof user !== "undefined") {
      return MENU_ITEMS_USER
    }
    return MENU_ITEMS_COMMON
  }, [user])

  return (
    <ul className={css.Root}>

      {!user &&
          <li className={css.MenuItemProfile}>
              <ProfileIcon/>
              <div className={css.Label}>
                  Профиль
                  <div className={css.MenuItemProfile_lock}>
                      <LockIcon/>
                  </div>
              </div>
          </li>
      }

      {MENU_ITEMS.map((item, index) => {
        if (!item.enabled) {
          return (
            <li className={css.MenuItemDisabled} key={index}>
              {item.icon}
              <div className={css.Label}>{item.label}</div>
            </li>
          );
        }

        const active =
          (item.pattern === "/" &&
            !router.pathname.includes(RouteEnum.PARTNERSHIP) &&
            !router.pathname.includes(RouteEnum.APPLICATIONS) &&
            !router.pathname.includes(RouteEnum.EVENTS) &&
            !router.pathname.includes(RouteEnum.EVENT) &&
            !router.pathname.includes(RouteEnum.FREELANCE) &&
            !router.pathname.includes(RouteEnum.SERVICES) &&
            !router.pathname.includes("cabinet")) ||
          router.pathname === item.pattern ||
          item.pattern.slice(0, -1) === router.pathname.slice(0, 6); // exactly contains 'event'

        return (
          <Link key={index} href={item.url} passHref>
            <li className={active ? css.MenuItemActive : css.MenuItem}>
              {item.icon}
              <div className={active ? css.LabelActive : css.Label}>
                {item.label}
              </div>
            </li>
          </Link>
        );
      })}

      {/*<Link href={"https://t.me/Eventica_bot"} passHref rel="noopener noreferrer" target="_blank">*/}
      {/*  <li className={css.MenuItemSoon}>*/}
      {/*    <FreelanceIcon/>*/}
      {/*    <div className={css.Label}>Услуги</div>*/}
      {/*  </li>*/}
      {/*</Link>*/}
    </ul>
  );
};

export default FooterMenu;
