import React from "react";

export const LockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M11.0833 5.83464H11.6667C11.8214 5.83464 11.9697 5.89609 12.0791 6.00549C12.1885 6.11489 12.25 6.26326 12.25 6.41797V12.2513C12.25 12.406 12.1885 12.5544 12.0791 12.6638C11.9697 12.7732 11.8214 12.8346 11.6667 12.8346H2.33333C2.17862 12.8346 2.03025 12.7732 1.92085 12.6638C1.81146 12.5544 1.75 12.406 1.75 12.2513V6.41797C1.75 6.26326 1.81146 6.11489 1.92085 6.00549C2.03025 5.89609 2.17862 5.83464 2.33333 5.83464H2.91667V5.2513C2.91667 4.71507 3.02229 4.18409 3.22749 3.68868C3.4327 3.19327 3.73347 2.74312 4.11265 2.36395C4.49182 1.98478 4.94196 1.684 5.43738 1.47879C5.93279 1.27359 6.46377 1.16797 7 1.16797C7.53623 1.16797 8.06721 1.27359 8.56262 1.47879C9.05804 1.684 9.50818 1.98478 9.88735 2.36395C10.2665 2.74312 10.5673 3.19327 10.7725 3.68868C10.9777 4.18409 11.0833 4.71507 11.0833 5.2513V5.83464ZM2.91667 7.0013V11.668H11.0833V7.0013H2.91667ZM6.41667 8.16797H7.58333V10.5013H6.41667V8.16797ZM9.91667 5.83464V5.2513C9.91667 4.47775 9.60938 3.73589 9.06239 3.18891C8.51541 2.64193 7.77355 2.33464 7 2.33464C6.22645 2.33464 5.48459 2.64193 4.93761 3.18891C4.39062 3.73589 4.08333 4.47775 4.08333 5.2513V5.83464H9.91667Z"
        fill="#9A41D1"/>
    </svg>
  )
}

export const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.33398 14.6693L14.6673 1.33594" stroke="#8A9299" strokeWidth="1.33333" strokeLinecap="round"/>
    <path d="M14.666 14.6693L1.33268 1.33594" stroke="#8A9299" strokeWidth="1.33333" strokeLinecap="round"/>
  </svg>
)