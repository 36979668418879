import s from './Notification.module.scss'

export const MailIcon = () => (
  <svg className={s.MailIcon} width="16" height="16" viewBox="0 0 16 16" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.667 2H1.33366C1.15685 2 0.987279 2.07024 0.862254 2.19526C0.73723 2.32029 0.666992 2.48986 0.666992 2.66667V13.3333C0.666992 13.5101 0.73723 13.6797 0.862254 13.8047C0.987279 13.9298 1.15685 14 1.33366 14H14.667C14.8438 14 15.0134 13.9298 15.1384 13.8047C15.2634 13.6797 15.3337 13.5101 15.3337 13.3333V2.66667C15.3337 2.48986 15.2634 2.32029 15.1384 2.19526C15.0134 2.07024 14.8438 2 14.667 2ZM14.0003 12.6667H2.00033V6.318L7.75233 8.61933C7.91164 8.68245 8.08901 8.68245 8.24832 8.61933L14.0003 6.318V12.6667ZM14.0003 4.882L8.00033 7.282L2.00033 4.882V3.33333H14.0003V4.882Z"
      fill="#8A9299"/>
  </svg>
)
