import {create} from "zustand";
import {devtools} from "zustand/middleware";

type EventPageState = {
  isLoading: boolean
  setIsLoading: (value: boolean) => void
}

export const useEventPageStore = create(devtools<EventPageState>(
  (set) => ({
    isLoading: false, //old - true
    setIsLoading: (value: boolean) => set(
      (state) => (
        {
          ...state,
          isLoading: value
        }
      )
    )
  })
))

