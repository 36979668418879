export const CollaborationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#8A9299" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16202 4.46868C4.32208 3.3084 5.87802 2.62976 7.51762 2.56893C9.15721 2.50809 10.7592 3.06956 12.002 4.14068C13.2438 3.07135 14.8436 2.51051 16.4812 2.57044C18.1189 2.63037 19.6734 3.30665 20.8337 4.46388C21.994 5.62112 22.6743 7.1739 22.7385 8.81137C22.8027 10.4488 22.2461 12.0501 21.18 13.2947L13.415 21.0847C13.0583 21.4416 12.5798 21.6504 12.0755 21.6693C11.5713 21.6882 11.0785 21.5158 10.696 21.1867L10.586 21.0857L2.82202 13.2947C1.75651 12.0512 1.1996 10.4514 1.26263 8.81506C1.32567 7.17873 2.004 5.62649 3.16202 4.46868ZM4.57602 5.88268C3.75826 6.70066 3.28687 7.80212 3.25969 8.95845C3.2325 10.1148 3.65161 11.2372 4.43002 12.0927L4.57602 12.2467L12.001 19.6717L17.304 14.3677L13.769 10.8327L12.709 11.8927C12.4305 12.1713 12.0998 12.3924 11.7358 12.5433C11.3718 12.6941 10.9817 12.7718 10.5877 12.7719C9.79201 12.7721 9.02881 12.4562 8.46602 11.8937C7.90323 11.3312 7.58695 10.5681 7.58676 9.77238C7.58658 8.97667 7.90249 8.21347 8.46502 7.65068L10.567 5.54768C9.73049 4.88005 8.68343 4.53237 7.61371 4.567C6.54398 4.60164 5.52161 5.01633 4.73002 5.73668L4.57602 5.88268ZM13.062 8.71068C13.2495 8.52321 13.5039 8.41789 13.769 8.41789C14.0342 8.41789 14.2885 8.52321 14.476 8.71068L18.718 12.9527L19.426 12.2467C20.2575 11.4158 20.7308 10.2926 20.7449 9.11724C20.7589 7.94188 20.3124 6.80769 19.501 5.95723C18.6896 5.10677 17.5777 4.60755 16.403 4.56633C15.2282 4.52511 14.084 4.94517 13.215 5.73668L13.062 5.88268L9.88002 9.06468C9.70669 9.23789 9.60299 9.46872 9.58861 9.71334C9.57424 9.95796 9.65018 10.1993 9.80202 10.3917L9.88002 10.4787C10.0532 10.652 10.2841 10.7557 10.5287 10.7701C10.7733 10.7845 11.0147 10.7085 11.207 10.5567L11.294 10.4787L13.062 8.71068Z"/>
  </svg>
);


export const FreelanceIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#8A9299" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.04996 14.1214L4.92996 16.2434L7.75796 19.0714L19.071 7.75743L16.243 4.93043L14.12 7.05044L15.535 8.46443L14.12 9.88043L12.706 8.46544L11.292 9.88043L12.706 11.2944L11.292 12.7084L9.87796 11.2944L8.46296 12.7084L9.87796 14.1224L8.46296 15.5374L7.04996 14.1204V14.1214ZM16.95 2.80843L21.192 7.05044C21.3794 7.23796 21.4847 7.49227 21.4847 7.75743C21.4847 8.0226 21.3794 8.27691 21.192 8.46443L8.46396 21.1924C8.27643 21.3799 8.02212 21.4852 7.75696 21.4852C7.49179 21.4852 7.23749 21.3799 7.04996 21.1924L2.80796 16.9504C2.62049 16.7629 2.51517 16.5086 2.51517 16.2434C2.51517 15.9783 2.62049 15.724 2.80796 15.5364L15.536 2.80843C15.7235 2.62096 15.9778 2.51565 16.243 2.51565C16.5081 2.51565 16.7624 2.62096 16.95 2.80843ZM14.12 18.3634L15.535 16.9494L17.777 19.1924H19.191V17.7784L16.949 15.5354L18.363 14.1214L21 16.7574V21.0004H16.758L14.121 18.3634H14.12ZM5.63596 9.87844L2.80696 7.05044C2.71398 6.95756 2.64022 6.84727 2.5899 6.72588C2.53957 6.60448 2.51367 6.47435 2.51367 6.34294C2.51367 6.21152 2.53957 6.08139 2.5899 5.95999C2.64022 5.8386 2.71398 5.72831 2.80696 5.63544L5.63596 2.80743C5.82349 2.61996 6.07779 2.51465 6.34296 2.51465C6.60812 2.51465 6.86243 2.61996 7.04996 2.80743L9.87996 5.63544L8.46396 7.05044L6.34296 4.92843L4.92896 6.34343L7.04996 8.46344L5.63596 9.87844Z"/>
  </svg>
);


export const ProfileIcon = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 19.9999H4C3.73478 19.9999 3.48043 19.8946 3.29289 19.707C3.10536 19.5195 3 19.2651 3 18.9999V9.99992H0L10.327 0.61192C10.5111 0.444398 10.7511 0.351562 11 0.351562C11.2489 0.351563 11.4889 0.444398 11.673 0.61192L22 9.99992H19V18.9999C19 19.2651 18.8946 19.5195 18.7071 19.707C18.5196 19.8946 18.2652 19.9999 18 19.9999ZM5 17.9999H17V8.15692L11 2.70292L5 8.15692V17.9999Z"
      fill="#8A9299"/>
  </svg>
);

export const EventsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#8A9299" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM20 11H4V19H20V11ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM6 13H8V15H6V13ZM11 13H13V15H11V13ZM16 13H18V15H16V13Z"/>
  </svg>
);