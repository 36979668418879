import clsx from "clsx";
import styles from "./notification-circle.module.scss";

export default function NotificationCircle({
  count,
  isAbsolute = true,
}: {
  count: number;
  isAbsolute?: boolean;
}) {
  return (
    <div
      className={clsx({
        [styles.NotificationCircle__circle]: true,
        [styles.NotificationCircle__circle_absolute]: isAbsolute,
      })}
      style={count === 0 ? { display: "none" } : {}}
    >
      <div className={styles.NotificationCircle__text}>{count}</div>
    </div>
  );
}
