import clsx from "clsx";

import { Button } from "ui";

import styles from "./tariffs-button.module.scss";

type PropsType = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
  variant: "orange" | "blue" | "orange_md";
};

export default function TariffsButton({
  children,
  className = "",
  variant,
  ...rest
}: PropsType) {
  return (
    <Button
      className={clsx({
        [styles["TariffsButton"]]: true,
        [styles["TariffsButton_" + variant]]: true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </Button>
  );
}
