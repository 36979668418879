import styles from "./Notification.module.scss";
import React from "react";
import { useRouter } from "next/router";
import { MailIcon } from "./Notification.svg";
import { NotificationCircle } from "ui/notification-circle";

type Props = {
  count: number;
};

export default function Notification({ count }: Props) {
  const router = useRouter();

  const handleClick = () => {
    router.push("/applications");
  };

  return (
    <div className={styles.Notification} onClick={handleClick}>
      <MailIcon />
      <NotificationCircle count={count} />
    </div>
  );
}
