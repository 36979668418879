import React from "react";
import { DateTime } from "luxon";

export function useDaysTillLeft<T>(date: string | undefined, dependecies: T[]) {
  const daysTillLeft = React.useMemo(() => {
    if (!date) return undefined;

    const now = DateTime.now();
    const end = DateTime.fromISO(date);
    const dif = end.diff(now, "day");
    const days = dif.toObject().days;

    return !days || days < 0 ? -1 : Math.ceil(days);
  }, [dependecies]);

  return daysTillLeft;
}
